import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import * as assign from 'lodash/assign';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import type { IDynamicBlocProps } from '../common';
import { getBlocTemplate } from '../../../services/itemshelpers';
import { TeaserItems } from '../teaseritems';
import { SortAndFilterItems } from '../teaseritems.props';
import type { States } from '../../../services/services';
import { registerBloc } from '../blocscatalog';

export interface IBlocItemsListState {
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
}

export function blocItemsListData(
    page: States.ICurrentPageState,
    blocdata,
    bloc: VisualTheme.IBlocContentTemplateBase,
    state: States.IAppState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dispatch: (action) => void
) {
    const data = (state.event?.data) || (state.community?.data) || (state.rootwebsite?.data);
    // eslint-disable-next-line no-param-reassign
    blocdata.itemtemplate = data && getBlocTemplate(data, bloc);
}

interface IBlocItemsListProps extends IDynamicBlocProps<IBlocItemsListState> {
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
export class BlocItemsList extends React.PureComponent<IBlocItemsListProps, any> {
    constructor(props: IBlocItemsListProps) {
        super(props);
        if (!props.blocState) {
            this.state = {
                itemtemplate: props.template
            };
        } else {
            this.state = {
                itemtemplate: this.props.blocState.itemtemplate
            };
        }
    }

    updateItems = (items) => {
        const properties = assign({}, this.props.template.properties, { items });
        const template = assign({}, this.props.template, { properties });
        this.props.patchContent(template);
    };

    getCurrentItems = () => {
        let items = [];
        const i18n = this.props.i18nHelper;
        if (this.props.template && this.props.template.properties) {
            items = this.props.template.properties.items ? this.props.template.properties.items : [];
        }

        if (this.props.template && this.props.template.properties
            && this.props.template.properties.fromNestedProperty
            && this.props.datacontext.entity
            && this.props.datacontext.entity[this.props.template.properties.fromNestedProperty]) {
            const downloadTitle = i18n.getTrad("actions.download");

            const entityData = this.props.datacontext.entity[this.props.template.properties.fromNestedProperty];
            items = entityData.filter((f) => f).sort((a, b) => {
                if (a.index > -1 && b.index > -1) {
                    return a.index - b.index;
                }
                return 1;
            }).map((efield) => {
                const picture = efield.thumbnail;
                let file = efield.file;
                if (file) {
                    file = i18n.translateBag(file);
                }

                return {
                    ...efield,
                    picture: picture && picture.url, // à virer quand les gabarits auront été mis à jour
                    $picture: picture && picture.url,
                    $link: file,
                    buttontitle: downloadTitle, // à virer quand les gabarits auront été mis à jour
                    $downloadtitle: downloadTitle
                };
            });
        }

        items = SortAndFilterItems(
            this.props.user,
            this.props.i18n,
            items,
            this.props.template.properties,
            false,
            this.props.page.context.entity
        );

        return items;
    };

    render() {
        if (!this.props.blocState && !this.state.itemtemplate) {
            return <div className="empty" />;
        }

        let item = this.props.blocState && this.props.blocState.itemtemplate
            ? this.props.blocState.itemtemplate
            : this.props.template.properties.template;

        if (!item) {
            item = this.state.itemtemplate;
        }

        const context = {
            ...this.props.datacontext,
            templateProperties: this.props.template.properties,
            templateOptions: this.props.blocState && this.props.blocState.itemtemplate
                && (this.props.blocState.itemtemplate as any).templateOptions
        };

        return <TeaserItems
            {...this.props as any}
            items={this.getCurrentItems()}
            datacontext={context}
            templateEditable
            templateProperties={this.props.template.properties.templateProperties}
            emptyMessage={this.props.template.properties.emptyMessage}
            allowEmpty={this.props.template.properties.allowEmpty}
            onEditData={this.updateItems}
            itemtemplate={item}
        />;
    }
}

registerBloc("itemslist", {
    component: BlocItemsList,
    datainit: blocItemsListData
});

registerBloc("videoslist", {
    component: BlocItemsList,
    datainit: blocItemsListData
});
